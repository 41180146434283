.mainPrimary {
  .main__container {
    //padding-top: 13rem;
    padding-bottom: 7rem;
    position: relative;
    .intro {
      .buttons__container {
        display: flex;
        //justify-content: space-between;
        //gap: calc(1rem + 13vw);
        .icons {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }
    }

    .marquee__container {
      //transform: rotate(20deg);
      .marquee__icons {
        //margin: 1rem auto;
        //padding Y o height ayuda a que los iconos
        //no tengan un techo de limitacion
        padding: 1.8rem 0;
        .marquee__ul {
          li {
            position: relative;
            //width: 4rem;
            //padding: 6rem;
            display: inline-block;
            //margin: 0 1rem;
            transition: all 0.2s ease-out;
            .icon__title {
              position: absolute;
              //left: -1%;
              z-index: 98;
              opacity: 0;
              height: 0;
              font-size: 0.9rem;
              font-weight: 600;
              margin-top: 0.2rem;
              text-align: center;
              width: 100%;
              transition:
                opacity 0.2s ease-out,
                height 0.2s ease-out;
            }
            &:hover {
              transform: scale(1.1); // Escala al 110% en hover
              // Mostrar el .icon__title cuando li esté en hover
              .icon__title {
                opacity: 1; // Mostrar el texto
                height: auto; // Ajustar la altura
              }
            }
          }
        }
        .marquee__ull {
          li {
            position: relative;
            //width: 4rem;
            //padding: 6rem;
            display: inline-block;
            //margin: 0 1rem;
            transition: all 0.2s ease-out;
            .icon__title {
              position: absolute;
              //left: -1%;
              z-index: 98;
              opacity: 0;
              height: 0;
              font-size: 0.9rem;
              font-weight: 600;
              margin-top: 0.2rem;
              text-align: center;
              width: 100%;
              transition:
                opacity 0.2s ease-out,
                height 0.2s ease-out;
            }
            &:hover {
              transform: scale(1.1); // Escala al 110% en hover
              // Mostrar el .icon__title cuando li esté en hover
              .icon__title {
                opacity: 1; // Mostrar el texto
                height: auto; // Ajustar la altura
              }
            }
          }
        }
      }
    }
    .about__container {
      // background-color: #0f0f0f;

      //background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10h10v10H0V10zM10 0h10v10H10V0z' fill='%231f1f1f' fill-opacity='0.10' fill-rule='evenodd'/%3E%3C/svg%3E");
      //background-color: rgb(11, 11, 11);

      .bacground__middle {
        //background-color: #171717;
      }
      .background__bottom {
        //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 100' fill='%23171717'%3E%3Cpath d='M0 100V0h1000v4L0 100z'%3E%3C/path%3E%3C/svg%3E");
        //background-repeat: no-repeat;
        //background-size: cover;
        //height: 150px; // Ajusta la altura según lo que necesitas
        //width: 100%;
      }
    }
    .Projets__container {
      .projects {
        /* /components/TuComponente.module.css */
      }
      /*@keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
  
        @keyframes slideUp {
          0% {
            transform: translateY(25px);
          }
          100% {
            transform: translateY(0);
          }
        }
  
        .fade {
          opacity: 0;
          transform: translateY(25px);
          transition:
            opacity 1s ease-in-out,
            transform 1s ease-in-out;
        }
  
        .visible {
          opacity: 1;
          transform: translateY(0);
          animation:
            fadeIn 1s ease-in-out forwards,
            slideUp 1.1s ease-out forwards;
        }*/
    }

    .buttonProjects__container {
      margin-top: 3rem;
      display: flex;
      justify-content: center;

      .button__projects {
        width: 200px;
        border-radius: var(--radius-xl);
        //border: 1px solid hsl(var(--border));
        background-color: rgba(55, 65, 81, 0.1);
        padding: 10px 25px;
        //color: #374151; // text-neutral-700
        transition:
          background-color 0.2s ease,
          color 0.2s ease; // transition
        &:hover {
          background-color: #a3a3a3; // hover:bg-neutral-700
          color: #0f0f0f; // hover:text-white
        }
      }
    }
    .techno__content {
      .technologies__utilises {
        .icon__skills {
          li {
            position: relative;
            //width: 4rem;
            //padding: 6rem;
            display: inline-block;
            //margin: 0 1rem;
            transition: all 0.2s ease-out;
            .icon__title {
              position: absolute;
              //left: -1%;
              z-index: 98;
              opacity: 0;
              height: 0;
              font-size: 0.9rem;
              font-weight: 600;
              margin-top: 0.2rem;
              text-align: center;
              width: 100%;
              transition:
                opacity 0.2s ease-out,
                height 0.2s ease-out;
            }
            &:hover {
              transform: scale(1.1); // Escala al 110% en hover
              // Mostrar el .icon__title cuando li esté en hover
              .icon__title {
                opacity: 1; // Mostrar el texto
                height: auto; // Ajustar la altura
              }
            }
          }
        }
        .icon__skillss {
          li {
            position: relative;
            //width: 4rem;
            //padding: 6rem;
            display: inline-block;
            //margin: 0 1rem;
            transition: all 0.2s ease-out;
            .icon__title {
              position: absolute;
              //left: -1%;
              z-index: 98;
              opacity: 0;
              height: 0;
              font-size: 0.9rem;
              font-weight: 600;
              margin-top: 0.2rem;
              text-align: center;
              width: 100%;
              transition:
                opacity 0.2s ease-out,
                height 0.2s ease-out;
            }
            &:hover {
              transform: scale(1.1); // Escala al 110% en hover
              // Mostrar el .icon__title cuando li esté en hover
              .icon__title {
                opacity: 1; // Mostrar el texto
                height: auto; // Ajustar la altura
              }
            }
          }
        }
      }
    }
  }
}
