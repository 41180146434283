.button {
  position: relative;
  cursor: pointer;
  //width: 260px; /* Ajuste del ancho */
  //height: 50px; /* Ajuste del alto */
  line-height: 1;
  //background: #000; /* Fondo inicial */
  // text-transform: uppercase;
  //font-size: 16px;
  //font-weight: bold;
  //letter-spacing: 4px;
  //border: none;
  padding: 1.5rem; // p-3
  border-radius: var(--radius-xl);
  //overflow: hidden; /* Evita que los efectos salgan del botón */
  //cursor: pointer;
  //display: flex;
  //align-items: center;
  justify-content: center;
  transition: color 0.5s ease;
  //text-decoration: none;

  transition:
    color 0.5s ease-in-out,
    -webkit-box-reflect 1.5s cubic-bezier(0.25, 0.1, 0.25, 1); /* Suaviza el reflejo */
  transition: -webkit-box-reflect 0.5s ease-in-out;

  /* Pseudo-elemento luminoso */
  &::before {
    border-radius: var(--radius-xl);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      #fb0094,
      #00f,
      #0f0,
      #ff0,
      #f00,
      #fb0094,
      #00f,
      #0f0,
      #ff0,
      #f00
    );
    background-size: 400%;
    opacity: 0;
    filter: blur(20px); /* Suaviza los bordes luminosos */
    transition: 0.5s;
    z-index: -1; /* Coloca detrás del contenido del botón */
  }
  &::after {
    content: '';
    border-radius: var(--radius-xl);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      #fb0094,
      #00f,
      #0f0,
      #ff0,
      #f00,
      #fb0094,
      #00f,
      #0f0,
      #ff0,
      #f00
    );
    background-size: 400%;
    opacity: 0;
    //filter: blur(20px); /* Suaviza los bordes luminosos */
    transition: 1s;
    //z-index: -1; /* Coloca detrás del contenido del botón */
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    animation: animate 20s linear infinite;
  }
  &:hover {
    //-webkit-box-reflect: below 1px -webkit-linear-gradient(transparent, #0004);
  }

  /* Animación del borde */
  @keyframes animate {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 300% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  /* Texto interno */
  .text {
    border-radius: var(--radius-xl);
    position: absolute;
    //display: block;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    //background: #0c0c0c; /* Fondo interno del texto */
    // color: rgba(255, 255, 255, 0.2); /* Color inicial del texto */
    // text-align: center;
    border-radius: inherit; /* Mantén el borde redondeado */
    z-index: 1; /* Coloca el texto por encima */
    transition: 0.5s ease-in-out;
  }

  &:hover .text {
  }

  /* Efecto de brillo en el texto 
  .text::before {
    border-radius: 25px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(255, 255, 255, 0.1);
    z-index: -1;
  }*/
}
