.skill__container {
  //border: 3px solid #171717;
  //z-index: 30;
  .skill__layer {
    //para darle un color girs a la imagen de granulos de bakcground
    filter: grayscale(100%);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-image: url('/images/g.webp');

      opacity: 0.09; // Controla la intensidad del granulado
      filter: grayscale(3);
    }
  }
}
