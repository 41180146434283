.button__link {
  display: flex;
  gap: 10px;
  border-radius: var(--radius-xl);
  border: 1px solid hsl(var(--border));
  background-color: rgba(55, 65, 81, 0.1);
  padding: 10px 25px;
  //color: #374151; // text-neutral-700
  transition:
    background-color 0.2s ease,
    color 0.2s ease; // transition
  &:hover {
    background-color: #404040; // hover:bg-neutral-700
    color: #ffffff; // hover:text-white
  }
}
