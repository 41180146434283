@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0);
  }
}

.fade__in {
  opacity: 0;
  transform: translateY(25px);
  transition:
    opacity 1s ease-in-out,
    transform 1s ease-in-out;
}

.fade__in.visible {
  opacity: 1;
  transform: translateY(0);
}
.noAnimation {
  animation: none;
}
/*.fade__in.visible {
  opacity: 1;
  transform: translateY(0);
  animation:
    fadeIn 1s ease-in-out forwards,
    slideUp 1.1s ease-out forwards;
}*/
