@keyframes float {
  0% {
    transform: translateY(0); /* Posición inicial */
  }
  50% {
    transform: translateY(-10px); /* Sube ligeramente */
  }
  100% {
    transform: translateY(0); /* Vuelve a la posición inicial */
  }
}

.floating__nav {
  z-index: 39;
  overflow: visible;
  //position: relative;
  transition: all 0.2s ease-in-out;
  //-webkit-backdrop-filter: invert(1) grayscale(1); // Inversión de colores + escala de grises
  //backdrop-filter: invert(1) grayscale(1); // Filtros compatibles con navegadores modernos
  animation: float 2s ease-in-out infinite;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;

    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;

    //background: #dddddd; /* Color de la ola */
    border-radius: 50%; /* Forma circular */
    transform: translate(-50%, -50%) scale(1); /* Centrado y tamaño inicial */
    //animation: pulse-wave 2s ease-in-out infinite; /* Animación suave con repetición infinita */
    z-index: -1; /* Coloca la ola detrás del botón */
  }
  &:hover {
    //transform: scale(1.1);
  }
}
