.button {
  cursor: pointer;
  //border: 1px solid black;
  border-radius: var(--radius-xl);
  //max-width: 15rem;
  justify-content: center;
  //background-color: rgba(55, 65, 81, 0.1); // bg-neutral-700/10
  padding: 0.9rem; // p-3
  line-height: 1; // leading-none
  //color: #374151; // text-neutral-700
  transition:
    background-color 0.2s ease,
    color 0.2s ease; // transition

  &:hover {
    background-color: #404040; // hover:bg-neutral-700
    color: #ffffff; // hover:text-white
  }
}
